<template>
    <div class="smsSettings">
        <div class="ml-20 mt-20 mb-20">小号调用次数</div>
        <div class="flex-nowrap-space-around-other">
            <div class="input-searchFrom-width-600">
                <el-form ref="upform" size="small" :model="searchForm" label-width="120px" :rules="rules">
                    <el-form-item label="分配用户" prop="uid">
                <el-select
                  v-model="searchForm.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
                    </el-form-item>
                    <el-form-item label="服务商：" prop="amountId">
                        <el-select v-model="searchForm.amountId" placeholder="请选择服务商" style="width: 100%" clearable filterable
              @change="changeValues1">
              <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
                    </el-form-item>
                    <el-form-item label="调用时间：" prop="valueTime">
                         <el-date-picker
                    v-model="valueTime"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                    value-format="YYYY-MM-DD"
                    :default-time="defaultTime"
                    size="mini"
                    :clearable='true'
                  >
                  </el-date-picker>
                       
                    </el-form-item>
                    <el-form-item label="">
                        <el-button class="yes-green-bg" @click="theQuery('upform')">导 出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="input-searchFrom-width-600 show-boxs">
             
               
            </div>
        </div>
        
    </div>
</template>

<script>
import amountts from '@/api/open/privacy/amount'
import userts from "@/api/web/user";
import {  ElMessage } from 'element-plus'
import { message } from "ant-design-vue";
export default {
    components: {

    },
    data () {
      
        return {
            amount_id:[],
            user_list: [], // 用户的集合
            valueTime: [], //选中时间段
            
            searchForm:{
                amountId:'',
                uid:'',
                dateBegin:'',
                dateEnd:''
                },
           
            rules: {
                uid: [
                    {
                    required: true,
                    message: '请选择导出的用户',
                    trigger: 'change',
                    },
                ],
                 amountId: [
                    {
                    required: true,
                    message: '请选择导出的服务商',
                    trigger: 'change',
                    },
                    
                ],
                  amountId: [
                    {
                    required: true,
                    message: '请选择导出的服务商',
                    trigger: 'change',
                    },
                    
                ],
                valueTime:{required: true,  trigger: 'change', validator: this.validateValueTime},
               
            }
        }
    },
    created() {
        
    },
    watch: {

    },
    mounted() {
           this.getAmountType()
           this.getUserList()
    },
    methods: {
         validateValueTime(rule, value, callback){
          value=this.valueTime
    if (value && value.length === 2 && value[0] && value[1]) {
      callback();
    } else {
      callback(new Error('请选择调用时间'));
    }
  },
         // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },
       theQuery(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
           this.searchForm.dateBegin=this.valueTime[0]
           this.searchForm.dateEnd=this.valueTime[1]
                    amountts.numberbindcount({
                      ...this.searchForm
                    }).then(res => {
                        console.log(res);
                        if(res.code == 200){
                             message.warning("正在导出...，请在消息模块下载");
                        }
                    })
                } else {
                console.log('error submit!!')
                return false
                }
            })
        },
       
    }
}
</script>

<style lang="scss" scoped>
    // .smsSettings{
    //     // margin-top: 60px;
    // }
    .show-boxs{
        height: 100%;
        border: 1px solid #0000;
    }
    .item-box-other{
        padding: 8px 16px;
        margin-bottom: 10px;
        border-radius: 4px;
        background: #ebebeb;
    }
</style>